<template>
  <div>
    <div v-if="firstBox">
      <div class="apply_box_top">
        <div class="apply_box_top_left">
          <div class="apply_box_total">
            <div class="apply_box_total_icon">
              <i class="iconfont icon-fapiaodengji"></i>
            </div>
            <div class="apply_box_total_text apply_box_total_text_special">
              <span>已申请融资发票总数(张)</span>
              <span class="apply_box_total_text50">{{ totalAccount }}</span>
              <span>/</span>
              <span>{{ list1[0].value }}</span>
            </div>
          </div>
        </div>
        <div class="apply_box_top_right">
          <div ref="echarts1" class="echarts_box"></div>
        </div>
      </div>
      <div class="table_common">
        <div class="search_flex_box">
          <div class="search_condition">
            <el-form ref="form" :model="form">
              <el-form-item label="融资编号:" class="financing_number financing_border" prop="number">
                <el-input v-model="form.number" @input="searchInput" maxlength="20">
                  <template slot="prepend">FN</template>
                </el-input>
              </el-form-item>
              <el-form-item label="融资金额:" prop="money">
                <el-input v-model="form.money" @input="searchMoney" maxlength="8"></el-input>
              </el-form-item>
              <el-form-item label="申请日期:" prop="date">
                <el-date-picker v-model="form.date" prefix-icon="iconfont icon-riqi" type="daterange" range-separator="至"
                  start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd"
                  :picker-options="applyDateChoose" :editable="false">
                </el-date-picker>
              </el-form-item>
              <el-form-item label="融资状态:" prop="state">
                <el-select v-model="form.state" placeholder="" class="select_class">
                  <el-option v-for="item in optionsState" :key="item.codeState" :label="item.value"
                    :value="item.codeState">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </div>
          <div class="search_boxs flex_center_between_box">
            <el-tooltip popper-class="search" effect="light" content="搜索" placement="top">
              <div class="search_button_box" @click="searchForm()">
                <i class="iconfont icon-10sousuo"></i>
              </div>
            </el-tooltip>
            <el-tooltip popper-class="search" effect="light" content="重置" placement="top">
              <div class="reset_button_box" @click="cleargetDate()">
                <i class="iconfont icon-shuaxin"></i>
              </div>
            </el-tooltip>
          </div>
        </div>
        <div class="table_box">
          <div class="table_box_top flex_center">
            <div class="table_box_top_title">我的融资</div>
            <div class="flex_center_end_box">
              <div class="search_button button data_export">数据导出</div>
            </div>
          </div>
          <div class="table_box_bottom">
            <el-table ref="treeTable" :data="tableData" stripe border style="width: 100%" :key="tableKey">
              <el-table-column type="expand" align="center" width="1" class-name="border_change">
                <template slot-scope="scope">
                  <div class="tree_table_box">
                    <el-table :data="scope.row.children" stripe border :key="onClickExpansionIndexkey"
                      style="width: 100%">
                      <el-table-column label="发票号" prop="invoiceNo" align="center" show-overflow-tooltip
                        :resizable="false" />
                      <el-table-column label="采购商名称" prop="purchaseName" align="left" header-align="center"
                        show-overflow-tooltip :resizable="false" :width="nameWidth" />
                      <el-table-column label="发票日期" sortable prop="invoiceDate" align="center" show-overflow-tooltip
                        :resizable="false" />
                      <el-table-column label="发票金额(元)" sortable prop="invoiceAmount" align="right" header-align="center"
                        show-overflow-tooltip :resizable="false">
                        <template slot-scope="scope">
                          {{ scope.row.invoiceAmount | NumFormat }}
                        </template>
                      </el-table-column>
                      <el-table-column label="发票评级" prop="invoiceGrade" align="center" show-overflow-tooltip
                        :resizable="false">
                        <template slot-scope="scope">
                          <div class="link" @click="clickInvoiceGrade(scope.row.invoiceGrade)">{{ scope.row.invoiceGrade
                          }}
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column label="发票账期(天)" sortable prop="accPeriod" align="center" show-overflow-tooltip
                        :resizable="false" />
                    </el-table>
                    <Page :totalFont=true :total=childrenpage.total :currentPage='childrenpage.pageNo'
                      @onPageChange="onPageChangeList"></Page>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="融资编号" prop="financingNum" align="center" show-overflow-tooltip :resizable="false"
                class-name="financing_cursor">
                <template slot-scope="scope">
                  <span class="expans_icon" :class="{ 'expandIconExpanded': scope.row.isExpand }"
                    @click="onClickExpansion(scope.row)"><i class="iconfont icon-youjiantou"></i>
                    {{ scope.row.financingNum }}</span>
                </template>
              </el-table-column>
              <el-table-column label="申请发票数量" sortable prop="financingInvoiceNum" align="right" header-align="center"
                show-overflow-tooltip :resizable="false" />
              <el-table-column label="融资金额(元)" sortable prop="financingAmount" align="right" header-align="center"
                show-overflow-tooltip :resizable="false">
                <template slot-scope="scope">
                  {{ scope.row.financingAmount | NumFormat }}
                </template>
              </el-table-column>
              <el-table-column label="申请日期" sortable prop="createDate" align="center" show-overflow-tooltip
                :resizable="false" />
              <el-table-column label="融资状态" prop="status" align="center" show-overflow-tooltip :resizable="false">
                <template slot-scope="scope">
                  <el-badge v-if="((scope.row.status == '1') || (scope.row.status == '9')) && scope.row.highly == '1'"
                    is-dot class="item">已被邀约</el-badge>
                  <div v-else-if="(scope.row.status == '1') || (scope.row.status == '9')">已被邀约</div>
                  <div v-else-if="scope.row.status == '0'">等待邀约</div>
                  <el-badge v-else-if="scope.row.status == '2' && scope.row.highly == '1'" is-dot
                    class="item">等待签约</el-badge>
                  <div v-else-if="scope.row.status == '2'">等待签约</div>
                  <el-badge v-else-if="scope.row.status == '3' && scope.row.highly == '1'" is-dot
                    class="item">等待放款</el-badge>
                  <div v-else-if="scope.row.status == '3'">等待放款</div>
                  <el-badge v-else-if="scope.row.highly == '1'" is-dot class="item">等待确认收款</el-badge>
                  <div v-else>等待确认收款</div>
                </template>
              </el-table-column>
              <el-table-column label="操 作" prop="operation" align="center" :resizable="false" :width="cz">
                <template slot-scope="scope">
                  <div class="table_oper flex_center">
                    <el-tooltip v-if="scope.row.isAgree == '1'" class="item_color_money_complete"
                      effect="light" content="选择投资方" placement="top">
                      <i class="iconfont icon-tixianzhanghu"></i>
                    </el-tooltip>
                    <el-tooltip v-else-if="scope.row.status == '1' || scope.row.status == '9'" class="item_color_money"
                      effect="light" content="选择投资方" placement="top">
                      <i class="iconfont icon-tixianzhanghu" @click="chooseFinancingButton(scope.row)"></i>
                    </el-tooltip>
                    <el-tooltip v-else-if="scope.row.status == '0'" class="item_color_money_complete" effect="light"
                      content="选择投资方" placement="top" :disabled="noButton">
                      <i class="iconfont icon-tixianzhanghu"></i>
                    </el-tooltip>
                    <el-tooltip v-if="scope.row.status == '2' && scope.row.codeFlag == '1'" class="item_color_money"
                      effect="light" content="合同处理" placement="top">
                      <i class="iconfont icon-hetongdingdan" @click="contractProcessing(scope.row)"></i>
                    </el-tooltip>
                    <el-tooltip v-else-if="scope.row.status == '2'" class="item_color_money_complete" effect="light"
                      content="合同处理" placement="top">
                      <i class="iconfont icon-hetongdingdan"></i>
                    </el-tooltip>
                    <el-tooltip v-if="scope.row.status == '4'||scope.row.status == '5'||scope.row.status == '6'||scope.row.status == '11'||scope.row.status == '12'" class="item_color_money" effect="light" content="融资详情" placement="top">
                      <i class="iconfont icon-xiangqingye-35" @click="financingDetails(scope.row)"></i>
                    </el-tooltip>
                    <el-tooltip v-if="scope.row.status == '10'" class="item_color_money" effect="light" content="放款详情"
                      placement="top">
                      <i class="iconfont icon-zhangdan" @click="loanDetailsOpen(scope.row)"></i>
                    </el-tooltip>
                    <el-tooltip v-if="scope.row.status == '3'" class="item_color_money_complete" effect="light"
                      content="放款详情" placement="top">
                      <i class="iconfont icon-zhangdan"></i>
                    </el-tooltip>
                    <el-tooltip class="item_color" effect="light" content="撤销申请" placement="top">
                      <i class="iconfont icon-chexiao2x" @click="revokeDialog(scope.row, scope.$index)"></i>
                    </el-tooltip>
                    <el-tooltip v-if="scope.row.status != '0'" class="item_color_money" effect="light" content="线下邀约"
                      placement="top">
                      <i class="iconfont icon-miantan" @click="offlineInvitationDialogTrue(scope.row)"></i>
                    </el-tooltip>
                    <el-tooltip v-else class="item_color_money_complete" effect="light" content="线下邀约" placement="top">
                      <i class="iconfont icon-miantan"></i>
                    </el-tooltip>

                    <el-tooltip class="item_color" effect="light" content="服务" placement="top">
                      <i class="iconfont icon--fuwu-xianxing"></i>
                    </el-tooltip>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="" prop="" align="center" show-overflow-tooltip :width='width30'
                class-name="add_padding" :resizable="false">
                <template slot="header">
                  <div class="table_add">
                    <i class="iconfont icon-jiahao"></i>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <Page :totalFont=true :total=page.total :currentPage='page.pageNo' @onPageChange="onPageChange"></Page>
        </div>
      </div>
      <!-- 发票评级弹窗 -->
      <el-dialog title="发票评级" :visible.sync="invoiceGradeDialog" center class="dialog_1268" :close-on-click-modal="false">
        <img :src="invoiceAImg" v-if="invoiceImg == 1">
        <img :src="invoiceBImg" v-else-if="invoiceImg == 2">
        <img :src="invoiceCImg" v-else-if="invoiceImg == 3">
        <img :src="invoiceDImg" v-else-if="invoiceImg == 4">
        <img :src="invoiceNaImg" v-else>
      </el-dialog>
      <!-- 合同处理弹框 -->
      <el-dialog title="合同处理" :visible.sync="contractProcessingDialog" center class="add_class dialog_width500"
        :close-on-click-modal="false">
        <div class="waiting_signing">等待签约</div>
        <div class="management_box">
          <div class="management_box_title flex_center_between_box">
            <div>资方合同材料下载:</div>
            <el-button class="download_button flex_center" @click="loadingFile()">
              <i class="iconfont icon-xiazai"></i>
              <span>下载全部材料</span>
            </el-button>
          </div>
          <ul class="ul">
            <li class="flex_center_box" v-for="(item, index) in upLoadFileName.manager" :key="index">
              <div class=" contract_download_box" v-if="item.type === '.docx'">
                <img :src="compressImg" alt="">
              </div>
              <div class=" contract_download_box" v-else-if="(item.type).substring((item.type).length - 5) == '.xlsx'">
                <i class="iconfont icon-Microsoft-Excel"></i>
              </div>
              <div class=" contract_download_box" v-else>
                <img :src="compressImgPdf" alt="">
              </div>
              <span class="file_name">{{ item.name }}</span>
            </li>
          </ul>
        </div>
        <div class="supplier_box">
          <el-form :model="uploadform" :rules="rules" ref="uploadform">
            <el-form-item label="供应商上传合同材料:" prop="file" style="white-space:nowrap; ">
              <ul class="ul" :key="indexindex">
                <li class="flex_center_box" v-for="(item, index) in upLoadFileName.manager" :key="index">
                  <div v-if="file[index].be" class="flex_center_box">
                    <div class=" contract_download_box" v-if="file[index].type == '.docx'">
                      <img :src="compressImg" alt="">
                    </div>
                    <div class=" contract_download_box"
                      v-else-if="(file[index].type).substring((file[index].type).length - 5) == '.xlsx'">
                      <i class="iconfont icon-Microsoft-Excel"></i>
                    </div>
                    <div class=" contract_download_box" v-else>
                      <img :src="compressImgPdf" alt="">
                    </div>
                    <span class="file_name">{{ file[index].name }}</span>
                    <div class="close_icon" @click="deleteUpload(index)" v-show="fileButton != '确认'">
                      <i class="iconfont icon-yuyinguanbi"></i>
                    </div>
                  </div>
                  <div v-else>
                    <el-upload ref="uploadForm" :http-request="handleUploadFile" action="fakeaction1"
                      v-model="form.paymentVoucher" class="contract_download_box contract_download_box_upload"
                      list-type="fileList" accept='.pdf,.docx,.xlsx'>
                      <div class="upload_box" @click="uploadIndex = index">
                        <i class="el-icon-plus"></i>
                      </div>
                    </el-upload>
                    <div></div>
                  </div>
                </li>
              </ul>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button class="footer_button2" round @click="contractProcessingDialog = false">取 消</el-button>
          <el-button class="footer_button1 button_class2" type="primary" @click="contractProcessSubmit()"
            v-show="fileBUttonShow">{{ fileButton }}</el-button>
          <el-button class="footer_button1 button_class1" type="primary" v-show="!fileBUttonShow">{{ fileButton
          }}</el-button>
          <!-- <el-button class="footer_button1" type="primary" @click="contractProcessSubmit()" :class="[fileButton?'button_class2':'button_class1']">提 交</el-button> -->
        </span>
      </el-dialog>
      <!-- 融资详情弹框 -->
      <el-dialog title="融资详情" :visible.sync="financingDetailsDialog" center class="dialog_width600"
        :close-on-click-modal="false">
        <div class="message_compare message_special flex_center_between_box">
          <span>我的融资信息</span>
          <span>投资方融资条件</span>
        </div>
        <div class="message_form message_detail">
          <el-form ref="financingForm" :model="financingForm">
            <el-form-item label="放款时间:">
              <el-input v-model="financingForm.supplierArrivalDate" disabled></el-input>
              <i class="iconfont icon-youjiantou"></i>
              <el-input v-model="financingForm.managementArrivalDate" disabled></el-input>
              <span class="data_compare_exhibition">
                <i class="iconfont icon-web-icon-" v-if="financingForm.timeRatio > 0"></i>
                <i class="iconfont icon-web-icon-1" v-else-if="financingForm.timeRatio < 0"></i>
                <span v-if="financingForm.timeRatio <= 0" class="compare_color1">{{ financingForm.timeRatio |
                  PositiveNumber }}天</span>
                <span v-else class="compare_color2">{{ financingForm.timeRatio }}天</span>
              </span>
            </el-form-item>
            <el-form-item label="还款时间:">
              <el-input v-model="financingForm.supplierRepaymentDate" disabled></el-input>
              <i class="iconfont icon-youjiantou"></i>
              <el-input v-model="financingForm.managementRepaymentDate" disabled></el-input>
              <span class="data_compare_exhibition">
                <i class="iconfont icon-web-icon-" v-if="financingForm.paymentTimeRatio > 0"></i>
                <i class="iconfont icon-web-icon-1" v-else-if="financingForm.paymentTimeRatio < 0"></i>
                <span v-if="financingForm.paymentTimeRatio <= 0" class="compare_color1">{{ financingForm.paymentTimeRatio
                  |
                  PositiveNumber }}天</span>
                <span v-else class="compare_color2">{{ financingForm.paymentTimeRatio }}天</span>
              </span>
            </el-form-item>
            <el-form-item label="融资金额:">
              <el-input v-model="financingForm.supplierFinancingAmount" disabled></el-input>
              <i class="iconfont icon-youjiantou"></i>
              <el-input v-model="financingForm.managementFinancingAmount" disabled></el-input>
              <span class="data_compare_exhibition">
                <i class="iconfont icon-web-icon-1" v-if="financingForm.amountRatio < 0"></i>
                <i class="iconfont icon-web-icon-" v-else-if="financingForm.amountRatio > 0"></i>
                <span v-if="financingForm.amountRatio <= 0" class="compare_color1">{{ financingForm.amountRatio |
                  PositiveNumber | NumFormat }}</span>
                <span v-else class="compare_color2">{{ financingForm.amountRatio | NumFormat }}</span>
              </span>
            </el-form-item>
            <el-form-item label="融资利率:">
              <el-input v-model="financingForm.supplierRate" disabled></el-input>
              <i class="iconfont icon-youjiantou"></i>
              <el-input v-model="financingForm.managementRate" disabled></el-input>
              <span class="data_compare_exhibition">
                <i class="iconfont icon-web-icon-" v-if="financingForm.rateRatio > 0"></i>
                <i class="iconfont icon-web-icon-1" v-else-if="financingForm.rateRatio < 0"></i>
                <span v-if="financingForm.rateRatio <= 0" class="compare_color1">{{ financingForm.rateRatio |
                  PositiveNumber }}%</span>
                <span v-else class="compare_color2">{{ financingForm.rateRatio }}%</span>
              </span>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button class="footer_button1" type="primary" @click="financingDetailsDialog = false; getDate()">关
            闭</el-button>
        </span>
      </el-dialog>
      <!-- 撤销弹框 -->
      <el-dialog title="撤销融资" :visible.sync="revokeFinancingDialog" center class="dialog_width500"
        :close-on-click-modal="false">
        <div class="revoke_confirm_box">
          是否撤销此条融资信息？
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button class="footer_button2" round @click="revokeFinancingDialog = false">取 消</el-button>
          <el-button class="footer_button1" type="primary" @click="confirmDialog()">确 认</el-button>
        </span>
      </el-dialog>
      <!-- 放款详情 -->
      <el-dialog title="放款详情" :visible.sync="loanDetailsDialog" center class="detail_width1000"
        :close-on-click-modal="false">
        <!-- <div class="waiting_signing">等待确认收款</div> -->
        <div class="waiting_signing" v-if="loanDetailsStatus == 12">已还款</div>
        <div class="waiting_signing" v-else>等待确认收款</div>
        <div class="add_invoice_message inner_common">
          <el-form :model="loanDetails">
            <el-row gutter="20">

              <el-col span="12">
                <div class="bg-purple-light">
                  <el-form-item label="融资编号:" class="form_item1">
                    <el-input v-model="loanDetails.financingNum" disabled></el-input>
                  </el-form-item>
                  <el-form-item label="融资企业名称:" class="form_item2">
                    <el-input v-model="loanDetails.financingName" disabled></el-input>
                  </el-form-item>
                  <el-form-item label="融资企业类型:" class="form_item3">
                    <el-select v-model="loanDetails.financingEnterpriseType" placeholder="" class="select_class" disabled>
                      <el-option v-for="item in financingEnterpriseTypeSelect" :key="item.codeState" :label="item.value"
                        :value="item.codeState">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="融资企业评级:" class="form_item3">
                    <el-input v-model="loanDetails.financingEnterpriseGrade" disabled></el-input>
                  </el-form-item>
                  <el-form-item label="融资发票数量:" class="form_item3">
                    <el-input v-model="loanDetails.financingInvoiceNum" disabled></el-input>
                  </el-form-item>
                  <el-form-item label="融资金额:" class="form_item4">
                    <el-input v-model="loanDetails.managementFinancingAmount" disabled></el-input>
                  </el-form-item>
                  <el-form-item label="发票金额:" class="form_item4">
                    <el-input v-model="loanDetails.sumInvoiceAmount" disabled></el-input>
                  </el-form-item>
                </div>
              </el-col>

              <el-col span="12">
                <div class="bg-purple-light2">
                  <el-form-item label="放款金额:" class="form_item1-1">
                    <el-input v-model="loanDetails.paymentAmount" disabled></el-input>
                  </el-form-item>
                  <el-form-item label="放款时间:" class="form_item1-2">
                    <!-- 骆 删除 开始 -->
                    <!-- <el-input v-model="loanDetails.managementArrivalDate" disabled></el-input> -->
                    <!-- 骆 删除 结束 -->
                    <!-- 骆 新增 开始 -->
                    <el-input v-model="loanDetails.actualLendingTime" disabled></el-input>
                    <!-- 骆 新增 结束 -->
                  </el-form-item>
                  <el-form-item label="放款银行:" class="form_item1-2">
                    <el-input v-model="loanDetails.paymentBank" disabled></el-input>
                  </el-form-item>
                  <el-form-item label="放款账号:" class="form_item1-2">
                    <el-input v-model="loanDetails.paymentAccount" disabled></el-input>
                  </el-form-item>
                  <el-form-item label="银行流水号:" class="form_item1-3">
                    <el-input v-model="loanDetails.managerBankSerialNumber" disabled></el-input>
                  </el-form-item>
                  <el-form-item label="是否分期:" class="form_item1-2">
                    <el-input v-model="loanDetails.isStage" disabled></el-input>
                  </el-form-item>
                  <el-form-item label="放款凭证:" class="form_item5">
                    <ul class="invimg_list flex_wrap">
                      <li v-for=" (item, index) in loanDetails.paymentVouchers" :key="index" class="flex_center">
                        <img :src="jpg + item">
                      </li>
                    </ul>
                  </el-form-item>
                </div>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <!-- <el-button class="footer_button2" round @click="loanDetailsDialog = false">取 消</el-button>
          <el-button class="footer_button1" type="primary" @click="applyFinancingDialogFalse()">提 交</el-button> -->
          <el-button class="footer_button1" v-show="loanDetailsStatus == 12" type="primary" @click="loanDetailsDialog = false">关 闭</el-button>
          <el-button class="footer_button2" v-show="loanDetailsStatus != 12" round @click="loanDetailsDialog = false">取 消</el-button>
          <el-button class="footer_button1" v-show="loanDetailsStatus != 12" type="primary" @click="applyFinancingDialogFalse()">提 交</el-button>
        </span>
      </el-dialog>
      <!-- 线下邀约 -->
      <el-dialog title="线下邀约" :visible.sync="offlineInvitationDialog" center class="dialog_width500"
        :close-on-click-modal="false">
        <div class="add_invoice_messages inner_common">
          <el-form :model="offlineInvitation" ref="offlineInvitation" :rules="offlineInvitationRule">
            <el-form-item label="被邀约对象:" prop="invitedName">
              <!-- <el-input v-model="offlineInvitation.invitedName" ></el-input> -->
              <el-autocomplete class="inline-input" v-model="offlineInvitation.invitedName"
                :fetch-suggestions="querySearchAsync" :trigger-on-focus="true" @select="handleSelect" clearable
                :disabled="offlineInvitation.manageName"></el-autocomplete>
            </el-form-item>
            <el-form-item label="邀约方式:" prop="invitationMethod">
              <el-select v-model="offlineInvitation.invitationMethod" placeholder="" class="select_class">
                <el-option v-for="item in offlineInvitationSelect" :key="item.codeState" :label="item.value"
                  :value="item.codeState">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="邀约时间:" prop="invitationTime" :class="exceptRepaymentClass ? 'exceptRepaymentClass' : ''">
              <el-date-picker v-model="offlineInvitation.invitationTime" :picker-options="invitationTimePicker"
                prefix-icon="iconfont icon-riqi" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                @change="exceptArrivalChange2()" :editable="false">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="邀约地点:" prop="invitationAddress" v-if="offlineInvitation.invitationMethod === '3'">
              <el-input v-model="offlineInvitation.invitationAddress"></el-input>
            </el-form-item>
            <el-form-item label="邀约内容:" v-if="offlineInvitation.invitationMethod === '3'">
              <el-input type="textarea" maxlength="200" :autosize="{ minRows: 3, maxRows: 3 }" show-word-limit
                v-model="offlineInvitation.invitationContent">
              </el-input>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button class="footer_button2" round @click="offlineInvitationDialog = false">取 消</el-button>
          <el-button class="footer_button1" type="primary" @click="sumbitofflineInvitation()">提 交</el-button>
        </span>
      </el-dialog>
    </div>
    <div v-if="secondBox">
      <div class="second_box">
        <div class="second_return_icon">
          <i class="iconfont icon-fanhui" @click="returnButton()"></i>
        </div>
        <div class="second_title_box">
          <span class="second_title1">
            选择资方
          </span>
          <span class="message_tips">（已有{{ carouselListNumber }}家资方向您提出邀约，系统为您匹配了最适合您的{{ carouselListNumberA
          }}家资方供您选择）</span>
        </div>
        <div class="second_choose_financing" :class="objStyle">
          <el-carousel indicator-position="outside" height="640px" :autoplay="false" arrow="always" :loop="false"
            @change="carouselChange">
            <!-- <el-carousel-item v-for="(item,index) in carouselList2" :key="index" >
                            <ul>
                                <li v-for="(child,indexs) in item.items" :key="indexs">
                                    <div class="li_title">{{child.managementName}}</div>
                                    <div class="li_content">
                                        <div class="li_content_bg">
                                            <div class="li_content_text">
                                                <span>融资额度(RMB)</span>
                                                <span :title="child.managementFinancingAmount">{{child.managementFinancingAmount | NumFormat}}</span>
                                            </div>
                                        </div>
                                        <div class="li_bottom">
                                            <div class="limit_date">
                                                <div class="limit_date_box1 limit_span">
                                                    <span>融资利率</span>
                                                    <span>{{child.managementRate}}%</span>
                                                </div>
                                                <div class="split_line"></div>
                                                <div class="limit_date_box1 limit_span">
                                                    <span>融资期限</span>
                                                    <span>{{child.managerRepaymentDay}}天</span>
                                                </div>
                                                <div class="split_line"></div>
                                                <div class="limit_date_box2 limit_span">
                                                    <span>融资成本</span>
                                                    <span>{{child.cost}}元</span>
                                                </div>
                                            </div>
                                            <div class="li_bottom_button">
                                                <div class="li_bottom_button_auto flex_center">
                                                    <div v-if="child.isAgree == 1" class="button button_not">邀约详情</div>
                                                    <div v-else class="button" @click="acceptInvitation(child.id)">邀约详情</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </el-carousel-item> -->
            <el-carousel-item v-for="(item, index) in carouselList" :key="index">
              <ul>
                <li v-for="(child, indexs) in item.items" :key="indexs">
                  <div class="li_title">{{ child.managementName }}</div>
                  <div  class="agree_tips" v-if="child.managementId == isArgeeManagementId">您已接受了该资方的邀约!</div>
                  <div class="li_content">
                    <div class="li_content_bg">
                      <div class="li_content_text">
                        <span>融资额度(RMB)</span>
                        <span :title="child.managementFinancingAmount">{{ child.managementFinancingAmount |
                          NumFormat }}</span>
                      </div>
                    </div>
                    <div class="li_bottom">
                      <div class="limit_date">
                        <div class="limit_date_box1 limit_span">
                          <span>融资利率</span>
                          <span>{{ child.managementRate }}%</span>
                        </div>
                        <div class="split_line"></div>
                        <div class="limit_date_box1 limit_span">
                          <span>融资期限</span>
                          <span>{{ child.managerRepaymentDay }}天</span>
                        </div>
                        <div class="split_line"></div>
                        <div class="limit_date_box2 limit_span">
                          <span>融资成本</span>
                          <span>{{ child.cost }}元</span>
                        </div>
                      </div>
                      <div class="li_bottom_button">
                        <div class="li_bottom_button_auto flex_center">
                          <div v-if="!carouseDisabled" class="button button_not">邀约详情</div>
                          <div v-else class="button" @click="acceptInvitation(child.id, child.managementId,child.financingNum,child.isAgree)">邀约详情</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>
    <div v-if="thirdBox">
      <div class="second_box">
        <div class="second_return_icon">
          <i class="iconfont icon-fanhui" @click="returnButton1()"></i>
          <span v-show="companyNameThirdShow">{{ companyNameThird }}</span>
        </div>
        <div class="second_title">
          我的邀约
        </div>
        <div class="second_invitation flex_box">
          <div class="second_invitation_left invitation_box"
            :class="repaymentDateShow ? 'heightRepaymentDate1' : 'heightRepaymentDate2'">
            <div class="second_invitation_title">放贷条件</div>
            <div class="message_compare flex_center_between_box">
              <span>我的融资信息</span>
              <span>投资方融资条件</span>
            </div>
            <div class="message_form">
              <el-form ref="financingForm" :model="financingForm">
                <el-form-item label="到账天数:">
                  <el-input v-model="financingForm.supplyRepaymentDay" disabled></el-input>
                  <i class="iconfont icon-youjiantou"></i>
                  <el-input v-model="financingForm.managerRepaymentDay" disabled></el-input>
                  <span class="data_compare_exhibition">
                    <i class="iconfont icon-web-icon-" v-if="financingForm.timeRatio > 0"></i>
                    <i class="iconfont icon-web-icon-1" v-else-if="financingForm.timeRatio < 0"></i>
                    <span v-if="financingForm.timeRatio <= 0" class="compare_color1">{{ financingForm.timeRatio |
                      PositiveNumber }}天</span>
                    <span v-else class="compare_color2">{{ financingForm.timeRatio }}天</span>
                  </span>
                </el-form-item>
                <el-form-item label="还款时间:" v-show="repaymentDateShow">
                  <el-input v-model="financingForm.supplierRepaymentDate" disabled></el-input>
                  <i class="iconfont icon-youjiantou"></i>
                  <el-input v-model="financingForm.managementRepaymentDate" disabled></el-input>
                  <span class="data_compare_exhibition">
                    <i class="iconfont icon-web-icon-" v-if="financingForm.paymentTimeRatio > 0"></i>
                    <i class="iconfont icon-web-icon-1" v-else-if="financingForm.paymentTimeRatio < 0"></i>
                    <span v-if="financingForm.paymentTimeRatio <= 0" class="compare_color1">{{
                      financingForm.paymentTimeRatio | PositiveNumber }}天</span>
                    <span v-else class="compare_color2">{{ financingForm.paymentTimeRatio }}天</span>
                  </span>
                </el-form-item>
                <el-form-item :label="accountShow">
                  <el-input v-model="financingForm.supplierFinancingAmount" disabled></el-input>
                  <i class="iconfont icon-youjiantou"></i>
                  <el-input v-model="financingForm.managementFinancingAmount" disabled></el-input>
                  <span class="data_compare_exhibition">
                    <i class="iconfont icon-web-icon-1" v-if="financingForm.amountRatio < 0"></i>
                    <i class="iconfont icon-web-icon-" v-else-if="financingForm.amountRatio > 0"></i>
                    <span v-if="financingForm.amountRatio <= 0" class="compare_color1">{{ financingForm.amountRatio |
                      PositiveNumber }}</span>
                    <span v-else class="compare_color2">{{ financingForm.amountRatio.includes(".") ? Number(financingForm.amountRatio).toFixed(2) : financingForm.amountRatio  }}</span>
                  </span>
                </el-form-item>
                <el-form-item :label="rateShow">
                  <el-input v-model="financingForm.supplierRate" disabled></el-input>
                  <i class="iconfont icon-youjiantou"></i>
                  <el-input v-model="financingForm.managementRate" disabled></el-input>
                  <span class="data_compare_exhibition">
                    <i class="iconfont icon-web-icon-1" v-if="financingForm.rateRatio < 0"></i>
                    <i class="iconfont icon-web-icon-" v-else-if="financingForm.rateRatio > 0"></i>
                    <span v-if="financingForm.rateRatio <= 0" class="compare_color1">{{ financingForm.rateRatio |
                      PositiveNumber }}%</span>
                    <span v-else class="compare_color2">{{ financingForm.rateRatio }}%</span>
                  </span>
                </el-form-item>
              </el-form>
            </div>
          </div>
          <div class="second_invitation_right invitation_box"
            :class="repaymentDateShow ? 'heightRepaymentDate1' : 'heightRepaymentDate2'">
            <div class="second_invitation_title my_second_invitation_title">我的反馈</div>
            <div class="my_feedback">
              <el-form ref="financingForm1" :model="financingForm" :rules="financingFormRules">
                <el-form-item label="期望到款天数:" :class="exceptArrivalClass ? 'exceptArrivalClass' : ''" prop="exceptArrival">
                  <!-- <el-date-picker
                                    v-model="financingForm.exceptArrival"
                                    prefix-icon="iconfont icon-riqi"
                                    type="date"
                                    @change="exceptArrivalChange()"
                                    value-format="yyyy-MM-dd"
                                    :picker-options="pickerOptions1">
                                    </el-date-picker> -->
                  <el-input @input="regNumber1" v-model="financingForm.exceptArrival" @change="regTime(), onChangeDay()"
                    maxlength="3"></el-input>
                </el-form-item>
                <el-form-item label="期望还款时间:" :class="exceptRepaymentClass ? 'exceptRepaymentClass' : ''" prop="exceptRepayment"
                  v-show="repaymentDateShow" :key="dateIndex">
                  <el-date-picker v-model="financingForm.exceptRepayment" prefix-icon="iconfont icon-riqi" type="date"
                    value-format="yyyy-MM-dd" :picker-options="pickerOptions2" @change="regTime()" @blur="onChangeDate()">
                  </el-date-picker>
                </el-form-item>
                <el-form-item :label="exceptAccountShow" :key="indexIndex" prop="exceptMoney">
                  <el-input v-model="financingForm.exceptMoney" :disabled="thirdAccountShow" @change="regTime" ref="inputRefF"
                    @focus="inputMoneyFocusfin(financingForm.exceptMoney)"
                    @blur="inputMoneyBlurfin(financingForm.exceptMoney)" @input="regNumber2" maxlength="8"></el-input>
                </el-form-item>
                <el-form-item :label="exceptRateShow" prop="exceptRate">
                  <el-input v-model="financingForm.exceptRate" @change="applyRateChange" @input="regNumber3"
                    maxlength="3"></el-input>
                </el-form-item>
              </el-form>
            </div>
          </div>
          <!-- 反馈之后禁止操作 -->
          <el-button type="primary" class="footer_button1 button_change1" @click="nextPrimary(1)" :disabled="isAgree==1"
            :class="[(backgroundColor && backgroundColorTime) ? 'bg_button1' : 'bg_button2']">反馈提交</el-button>
          <el-button type="primary" class="footer_button1 button_change" @click="nextPrimary(2)" :disabled="isAgree==1"
            :class="[!backgroundColor ? 'bg_button1' : 'bg_button2']">接受邀约</el-button>
          <el-button type="primary" class="footer_button1 button_change2" @click="nextPrimary(3)" :disabled="isAgree==1">拒绝邀约</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getSupplierList, findCodeInfoByKey, revokeFinancing, findAllMyManageByFinancingNum, getInvoiceList,
  updateFinancingComparisonInfo, acceptInvite, getFinancingCompList, contract, refuseInvite, uploadContractSupplier,
  downloadContractSupplier, getContractSupplier, uploads, getDetailLoan, surePayment, offlineInvitation, getSelectInvation
} from "@/api/supplier.js";
import { mixins1 } from "@/mixins/index";
import Page from '@/components/page'
export default {
  mixins: [mixins1],
  components: {
    Page
  },
  data() {
    const valiedateLin = (rule, val, cb) => {
      if ((val < 0) || (val >= 100)) {
        this.backgroundColorTime = true
        return cb(new Error('请输入0-100之间数字'))
      }
      this.backgroundColorTime = true
      return cb()
    }
    const valiedateprecon = (rule, val, cb) => {
      if (!val) {
        return cb(new Error('请输入被邀约对象'))
      }
      return cb()
    }
    const valiedateon = (rule, val, cb) => {
      if (!val) {
        return cb(new Error('请输入邀约方式'))
      }
      return cb()
    }
    const valiedateTime = (rule, val, cb) => {
      if (!val) {
        return cb(new Error('请输入邀约时间'))
      }
      return cb()
    }
    const valiedated = (rule, val, cb) => {
      if (!val && (this.offlineInvitation.invitationMethod == '3')) {
        return cb(new Error('请输入邀约地点'))
      }
      return cb()
    }
    return {
      isAgree:0,
      indexIndex: '1',
      dateIndex:'1',
      restaurants: [],
      FN: '',
      offlineInvitationSelect: [],
      financingEnterpriseTypeSelect: [],
      indexindex: '1',
      uploadIndex: '1',
      offlineInvitationDialog: false,
      offlineInvitation: {
        invitationName: '',
        invitedName: '',
        invitationMethod: '0',
        invitationTime: '',
        invitationAddress: '',
        invitedId: '',
        manageName: ''
      },
      loanDetails: {
        paymentImg: ''
      },
      fapiaomoney: '',
      item: true,
      backgroundColorTime: true,
      optionsState: [],
      revokeFinancingDialog: false,
      exceptArrivalClass: false,
      exceptRepaymentClass: false,
      loanDetailsDialog: false,
      loanDetailsStatus: '',
      carouselIndex: 0, //轮播索引默认为0
      carouseDisabled:true,
      isArgeeManagementId:'',
      carouselList: [
        {
          items: [

          ]
        }
      ],
      carouselList2: [
        {
          items: [

          ]
        }
      ],
      carouselListNumber: '',
      carouselListNumberA: '',
      form: {
        number: '',
        money: '',
        date: '',
        state: '',
        startDate: '',
        endDate: ''
      },
      financingFormRules: {
        exceptRate: [
          { validator: valiedateLin, trigger: 'change' }
        ],
      },
      offlineInvitationRule: {
        invitedName: [
          { required: true, validator: valiedateprecon }
        ],
        invitationMethod: [
          { required: true, validator: valiedateon, trigger: 'blur' }
        ],
        invitationTime: [
          { required: true, validator: valiedateTime, trigger: 'blur' }
        ],
        invitationAddress: [
          { required: true, validator: valiedated, trigger: 'blur' }
        ],
      },
      financingForm: {
        supplierArrivalDate: '',
        managementArrivalDate: '',
        supplierRepaymentDate: '',
        managementRepaymentDate: '',
        supplierFinancingAmount: '',
        managementFinancingAmount: '',
        supplierRate: '',
        managementRate: '',
        financingRate3: '',
        exceptArrival: '',
        exceptRepayment: null,
        exceptMoney: '',
        exceptRate: '',
        id: ""
      },
      tableKey: 0,
      id: '',
      tableData: [
      ],
      listQuery: {
        total: 0
      },
      list1: [
        {
          value: 0,
          name: '已邀约发票'
        },
        {
          value: 0,
          name: '未邀约发票'
        },
      ],
      uploadform: {
        file: ''
      },
      rules: {
        file: [{ required: true, message: "请上传图片", trigger: "change" }]
      },
      compressImg: require('@/assets/img/system/supplier/word.png'),
      compressImgPdf: require('@/assets/img/pdf.png'),
      invoiceAImg: require('@/assets/img/system/invoice_A.png'),
      invoiceBImg: require('@/assets/img/system/invoice_B.png'),
      invoiceCImg: require('@/assets/img/system/invoice_C.png'),
      invoiceDImg: require('@/assets/img/system/invoice_D.png'),
      invoiceNaImg: require('@/assets/img/system/invoice_NA.png'),
      noButton: true,
      firstBox: true,
      secondBox: false,
      thirdBox: false,
      compareDateRise: '7',
      compareDateRise1: '7',
      compareDateRise2: '7,000',
      compareDateRise3: '2',
      compareDateDecline: '',
      compareDateEqual: '0',
      compareRise: true,
      compareDecline: false,
      totalAccount: 0,
      alreadyInviteNum: 0,
      contractProcessingDialog: false,
      primaryClass: true,
      financingDetailsDialog: false,
      item1: true,
      backgroundColor: false,
      backgroundColor1: true,
      revokeDialogId: '',
      page: {
        pageNo: 1,
        pageSize: 8,
        total: 0
      },
      childrenpage: {
        pageNo: 1,
        pageSize: 8,
        total: 0
      },
      pickerOptions1: {
        disabledDate: (time) => {
          if (this.financingForm.exceptRepayment != undefined) {
            return (
              time.getTime() > new Date(this.financingForm.exceptRepayment).getTime() - 30 * 24 * 3600 * 1000 || time.getTime() < Date.now()
            )
          } else {
            return time.getTime() < Date.now()
          }
        }
      },
      pickerOptions2: {
        disabledDate: (time) => {
          if (this.financingForm.exceptArrival) {
            return (
              time.getTime() < new Date(this.financingForm.createDate).getTime() + (parseInt(this.financingForm.exceptArrival)) * 24 * 3600 * 1000 + 30 * 24 * 3600 * 1000
            )
          } else {
            return time.getTime() < new Date(this.financingForm.createDate).getTime() + (parseInt(this.financingForm.managerRepaymentDay)) * 24 * 3600 * 1000 + 30 * 24 * 3600 * 1000
          }
        }
      },
      invitationTimePicker: {
        disabledDate: (time) => {
          if (this.offlineInvitation.invitationTime == '') {
            return (
              time.getTime() < Date.now()
            )
          } else {
            return (
              time.getTime() < Date.now()
            )
          }
        }
      },
      file: [],
      files: [],
      uploadFileError: false,
      contractProcessSubmitdata: {
        financingNum: '',
        manageId: '',
        codeFlag: ''
      },
      fileButton: '保存',
      upLoadFileNumber: '',
      upLoadFileName: {},
      onClickExpansionFinancingNum: '',
      onClickExpansionIndex: '',
      onClickExpansionIndexkey: 1,
      returnBUttonSpecial: '',
      companyNameThird: '',
      companyNameThirdShow: false,
      repaymentDateShow: true,
      fileBUttonShow: false,
      accountShow: '',
      rateShow: '',
      exceptAccountShow: '',
      exceptRateShow: '',
      percentStr: 0,
      percentStrs: 0,
      investmentObjectNum: '',
      thirdAccountShow: false,
      fapiaoAccount: '',
      c: '',
      finName: '',
      mangentId: '',
      invoiceGradeDialog: false,
      invoiceImg: ''
    }
  },
  created() {
    this.getDate()
    this.getSelect()
    this.resizeFn();
    this.tableWidth()
  },
  computed: {
    objStyle() {
      return {
        'leftArrow': this.carouselIndex == 0,
        'rightArrow': this.carouselIndex == 1
      }
    }
  },
  methods: {
    isAgreeLength(){
      let agreeLength=this.carouselList[0].items.filter(item=>item.isAgree==1)
      console.log(agreeLength)
      return agreeLength.length
    },
    exceptArrivalChange2() {
      if (this.offlineInvitation.invitationTime) {
        this.exceptRepaymentClass = true
      }
      else {
        this.exceptRepaymentClass = false
      }
    },
    // 发票评级提示
    clickInvoiceGrade(val) {
      this.invoiceGradeDialog = true
      if (val == 'A') {
        this.invoiceImg = 1
      } else if (val == 'B') {
        this.invoiceImg = 2
      } else if (val == 'C') {
        this.invoiceImg = 3
      } else if (val == 'D') {
        this.invoiceImg = 4
      } else {
        this.invoiceImg = 5
      }
    },
    searchInput(val) {
      val = val.replace(/[^\d]/g, '')
      this.form.number = val
    },
    searchMoney(val) {
      val = val.replace(/[^\d]/g, '')
      this.form.money = val
    },
    // 期望到期天数改变时，期望还款时间清空
    onChangeDay() {
      console.log('11',this.financingForm.exceptRepayment)
      this.financingForm.exceptRepayment = null
    },
    onChangeDate(val){
      console.log("22",val)
      this.dateIndex++
    },
    regTime() {
      console.log(this.backgroundColorTime)
      if (this.returnBUttonSpecial == 3) {
        if (this.financingForm.exceptArrival || this.financingForm.exceptRate) {
          this.backgroundColor = true
        } else {
          this.backgroundColor = false
        }
      } else {
        if (this.financingForm.exceptArrival || this.financingForm.exceptRepayment || this.financingForm.exceptMoney || this.financingForm.exceptRate) {
          this.backgroundColor = true
        } else {
          this.backgroundColor = false
        }
      }
    },
    regNumber1(val) {
      val = val.replace(/[^\d]/g, '')
      this.financingForm.exceptArrival = val
    },
    regNumber2(val) {
      // this.$refs.inputRefF.blur()
      val = val.replace(/[^\d]/g, '')
      console.log(33,val)
      this.financingForm.exceptMoney = val
      this.$set(this.financingForm,'exceptMoney',val)
      this.$nextTick(()=>{
        this.$refs.inputRefF.focus()
      })
    },
    regNumber3(val) {
      val = val.replace(/[^\d]/g, '')
      this.financingForm.exceptRate = val
    },
    querySearchAsync(queryString, cb) {
      getSelectInvation('FN=' + this.FN + '&CnName=' + queryString).then((data) => {
        let res = data.data.map(function (value) {
          let obj = {}
          obj.value = value
          return obj
        })
        this.restaurants = res
        var restaurants = this.restaurants;
        var results = []
        if (restaurants) {
          results = queryString ? restaurants.filter(this.createStateFilter(queryString)) : restaurants;
          // results = restaurants;
        }

        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          cb(results);
        }, 1000 * Math.random());
      })
    },
    createStateFilter(queryString) {
      return (state) => {
        return (state.value.toLowerCase().indexOf(queryString.toLowerCase())!==-1);
      };
    },
    async offlineInvitationDialogTrue(row) {
      this.$nextTick(() =>
        this.$refs.offlineInvitation.clearValidate()

      )
      this.offlineInvitation = {
        invitationName: '',
        invitedName: '',
        invitationMethod: '0',
        invitationTime: '',
        invitationAddress: '',
        invitedId: '',
        manageName: ''
      },
        this.offlineInvitation.manageName = row.manageName
      this.FN = row.financingNum
      if (!row.manageName) {
        this.offlineInvitationDialog = true
      } else {
        this.offlineInvitation.invitationId = row.userId,
          this.offlineInvitation.invitedName = row.manageName,
          this.offlineInvitation.invitedId = row.manageId,
          this.offlineInvitationDialog = true
        // this.offlineInvitation.invitationTime=row.paymentDate
      }
    },
    async applyFinancingDialogFalse() {
      let data = await surePayment('FN=' + this.loanDetails.financingNum + '&supplierId=' + this.loanDetails.userCode + '&managerId=' + this.loanDetails.managementId)
      this.loanDetailsDialog = false
      this.getDate()
    },
    async loanDetailsOpen(value) {
      let data = await getDetailLoan('FN=' + value.financingNum + '&supplierId=' + value.userId + '&managerId=' + value.manageId)
      if (data.code == '200') {
        this.loanDetails = data.data
        this.loanDetails.isStage = this.loanDetails.isStage == 1 ? '是' : '否'
        this.loanDetails.sumInvoiceAmount = this.filter(this.loanDetails.sumInvoiceAmount)
        this.loanDetails.managementFinancingAmount = this.filter(this.loanDetails.managementFinancingAmount)
        this.loanDetails.paymentAmount = this.filter(this.loanDetails.paymentAmount)
        this.loanDetailsDialog = true
        this.loanDetailsStatus = value.status
      }
    },
    sumbitofflineInvitation() {
      this.$refs.offlineInvitation.validate(async (valid) => {
        if (valid) {
          let data = await offlineInvitation(this.FN, this.offlineInvitation)
          this.offlineInvitationDialog = false
          this.getDate()
        } else {
          return false;
        }
      });
    },
    inputMoneyFocusfin(value) {
     
      // if(!this.returnBUttonSpecial == 3){
      this.$nextTick(()=>{
        this.financingForm.exceptMoney = this.filterOut(value)
      })
      this.$set(this.financingForm,'exceptMoney',this.filterOut(value))
      // }
    },
    inputMoneyBlurfin(value) {
      
      // if(!this.returnBUttonSpecial == 3){
      this.financingForm.exceptMoney = this.filter(value)
      this.$set(this.financingForm,'exceptMoney',this.filter(value))
      // }
    },
    async onPageChangeList(val) {
      console.log(val, 1011);
      this.childrenpage.pageNo = val
      let data = await getInvoiceList('pageNo=' + this.childrenpage.pageNo + '&pageSize=' + this.childrenpage.pageSize + '&financingNum=' + this.onClickExpansionFinancingNum)
      this.childrenpage.total = data.data.total
      this.tableData[this.onClickExpansionIndex].children = data.data.records
      this.onClickExpansionIndexkey++
    },
    deleteUpload(index) {
      this.file[index] = {
        url: '',
        name: '',
        be: '',
        type: ''
      }
      console.log(this.file[index])
      this.indexindex++
    },
    handlePictureCardPreview(file) {
      console.log(file);
    },
    handleAvatarSuccess(res, file) {
      console.log(res, file)
    },
    async handleUploadFile(params, index) {
      if ((params.file.name.substring((params.file.name).length - 5) == '.xlsx') || (params.file.name.substring((params.file.name).length - 5) == '.docx') || (params.file.name.substring((params.file.name).length - 4) == '.pdf')) {
        console.log(params.file.name.substring((params.file.name).length - 5))
        let form = new FormData();
        form.append("files", params.file);
        let data = await uploads(form)
        this.file[this.uploadIndex].url = data[0]
        this.file[this.uploadIndex].name = params.file.name
        this.file[this.uploadIndex].be = '1'
        if (this.file[this.uploadIndex].type.indexOf('.xlsx')) {
          this.file[this.uploadIndex].type = params.file.name.substring((params.file.name).length - 5)
        }
        else {

          this.file[this.uploadIndex].type = '.' + params.file.name.split('.')[1]
        }

        console.log(this.file)
        let i = 0
        this.file.forEach((item, index) => {
          console.log(item)
          if (item.be) {
            ++i
          }
        })
        // console.log(i,this.upLoadFileName.manager.length)
        // console.log(this.upLoadFileName.manager)
        if (this.upLoadFileName.manager.length) {
          this.fileBUttonShow = true
        }
        if (i == this.upLoadFileName.manager.length) {
          this.fileButton = '提交'
          this.fileBUttonShow = true
        }

        this.indexindex++
      }
    },
    async getDate() {
      console.log(this.form);
      if (this.form.date != null) {
        if (this.form.date[0]) {
          this.form.startDate = this.form.date.slice(',')[0]
          this.form.endDate = this.form.date.slice(',')[1]
        } else {
          this.form.startDate = ''
          this.form.endDate = ''
        }
      } else {
        this.form.startDate = ''
        this.form.endDate = ''
      }
      let data = await getSupplierList('pageNo=' + this.page.pageNo + '&pageSize=' + this.page.pageSize + '&financingNum=' + this.form.number + '&financingAmount=' +
        this.form.money + '&startDate=' + this.form.startDate + '&endDate=' + this.form.endDate + '&status=' + this.form.state)
      console.log(data.data.records, 975);
      console.log(this.offlineInvitation.invitationName, 1026);
      this.tableData = data.data.records
      this.tableData.forEach((item, index) => {
        this.tableData[index].isExpand = false
        this.tableData[index].children = []
      });
      this.page.total = data.data.total
      this.list1[1].value = data.unsolicitedCount
      this.list1[0].value = data.invitedCount
      this.totalAccount = data.financedCount
      this.$nextTick(function () {
        this.riskEchart2(
          this.fontSize,
          this.echartsWidth,
          this.width30
        )
      })
    },
    async getSelect() {
      let data = await findCodeInfoByKey('ms=5')
      this.optionsState = data.data
      data = await findCodeInfoByKey('ms=8')
      this.financingEnterpriseTypeSelect = data.data
      data = await findCodeInfoByKey('ms=17')
      this.offlineInvitationSelect = data.data
    },
    searchForm() {
      this.page.pageNo = 1
      this.getDate()
    },
    cleargetDate() {
      this.$refs.form.resetFields()
      this.childrenpage.pageNo = 1
      this.page.pageNo = 1
      this.getDate()
    },
    // 撤销
    revokeDialog(row, index) {
      this.revokeDialogId = row.financingNum
      this.revokeFinancingDialog = true
    },
    // 确认撤销
    async confirmDialog() {
      let data = await revokeFinancing('financingNum=' + this.revokeDialogId)
      this.revokeFinancingDialog = false
      this.getDate()
    },
    exceptArrivalChange() {
      if (this.financingForm.exceptRepayment) {
        this.exceptRepaymentClass = true
      } else {
        this.exceptRepaymentClass = false
      }
      if (this.returnBUttonSpecial == 3) {
        if (this.financingForm.exceptArrival || this.financingForm.exceptRate) {
          this.backgroundColor = true
        } else {
          this.backgroundColor = false
        }
        if (!this.financingForm.exceptArrival && !this.financingForm.exceptRate) {
          this.backgroundColor = false
        }
      }
      else {
        this.backgroundColorTime = true
        if (this.financingForm.exceptArrival || this.financingForm.exceptRepayment || this.financingForm.exceptRate) {
          this.backgroundColor = true
        } else {
          this.backgroundColor = false
        }
      }
    },
    // 合同下载
    loadingFile() {
      // for (let i = 0;i<this.upLoadFileNumber.length;i++) {
      // window.location.href="/file/合同.docx"
      // }
      // window.open(this.buildForm[name]);
      // window.open("https://wenku.baidu.com/view/24c80d20793e0912a21614791711cc7930b77874.html");
      this.upLoadFileNumber.forEach((item, index) => {
        //  console.log(item)
        var link = document.createElement("a");
        link.setAttribute("download", this.upLoadFileName.manager[index]?.name?this.upLoadFileName.manager[index].name:this.upLoadFileName.manager[index]);
        link.href = this.jpg + item;
        link.target="_blank"
        // link.href = item;
        link.click();
      });
      // link.remove();
    },
    // echsrts图表参数
    resizeFn() {
      if (document.body.clientWidth > 1664) {
        this.fontSize = 14
        this.echartsWidth = 36
        this.width30 = 30
        this.cz = 270
      } else {
        this.fontSize = 10
        this.echartsWidth = 24
        this.width30 = 20
        this.cz = 180
      }
    },
    // 展开子表格
    async onClickExpansion(row) {
      this.onClickExpansionFinancingNum = row.financingNum
      let data = await getInvoiceList('pageNo=' + this.childrenpage.pageNo + '&pageSize=' + this.childrenpage.pageSize + '&financingNum=' + row.financingNum+'&highly=0')
      this.childrenpage.total = data.data.total
      this.tableData.map((item, index) => {
        if (row.financingNum != item.financingNum) {
          item.isExpand = false;
          this.$refs.treeTable.toggleRowExpansion(item, false);
        } else {
          this.onClickExpansionIndex = index
          this.tableData[index].children = data.data.records
          item.isExpand = !item.isExpand
        }
      })
      this.$refs.treeTable.toggleRowExpansion(row)
    },
    // 选择资方
    async chooseFinancingButton(row) {
      console.log(row.investmentObject)
      this.returnBUttonSpecial = row.investmentObject
      // this.financingForm.financingNum = row.financingNum
      // this.financingForm.managementId = row.userId
      this.finName = row.financingNum
      if (row.investmentObject == 3) {
        this.firstBox = false
        this.secondBox = false
        this.thirdBox = true
        this.repaymentDateShow = false
        this.companyNameThirdShow = true
        this.thirdAccountShow = true
        this.accountShow = '付款金额:'
        this.rateShow = '折扣利率:'
        this.exceptAccountShow = '期望付款金额:'
        this.exceptRateShow = '期望折扣利率:'
        window.scrollTo(0, 0)
        let res = await findAllMyManageByFinancingNum('financingNum=' + row.financingNum)
        this.financingForm = res.data.listAll[0]
        this.companyNameThird = res.data.listAll[0].managementName
        this.financingForm.supplierFinancingAmount = res.state
        this.financingForm.managementFinancingAmount = res.state
        var str = (100 - (this.financingForm.supplierRate)).toString()
        var reg = RegExp(/./);
        if (str.match(reg)) {
          var percent = (str || '').split('.')
          var num = percent.join('')
          this.percentStr = '0' + '.' + Number(num)
        }
        this.financingForm.supplierFinancingAmount = this.filter(this.financingForm.supplierFinancingAmount * this.percentStr)
        var strs = (100 - (this.financingForm.managementRate)).toString()
        var regs = RegExp(/./);
        if (strs.match(regs)) {
          var percents = (strs || '').split('.')
          var nums = percents.join('')
          this.percentStrs = '0' + '.' + Number(nums)
        }
        this.financingForm.managementFinancingAmount = this.filter(this.financingForm.managementFinancingAmount * this.percentStrs)
        this.financingForm.amountRatio = (Math.abs(this.filterOut(this.financingForm.supplierFinancingAmount) - this.filterOut(this.financingForm.managementFinancingAmount))).toFixed(2)
        this.financingForm.supplierFinancingAmount = this.filter(this.financingForm.supplierFinancingAmount)
        this.financingForm.managementFinancingAmount = this.filter(this.financingForm.managementFinancingAmount)
        this.financingForm.supplierRate = this.financingForm.supplierRate + '%'
        this.financingForm.managementRate = this.financingForm.managementRate + '%'
        this.exceptArrivalChange()
        this.fapiaomoney = res.state
        console.log(res)
        this.financingForm.exceptMoney = this.filter(res.state).toString()
        console.log(typeof this.financingForm.exceptMoney)
        this.applyRateChange()
      }
      else {
        this.firstBox = false
        this.carouselIndex = 0
        this.secondBox = true
        this.thirdBox = false
        window.scrollTo(0, 0)
        let data = await findAllMyManageByFinancingNum('financingNum=' + row.financingNum)
        this.financingForm = data.data.listAll[0]
        this.carouselListNumber = data.data.listAllSize
        this.carouseDisabled = data.data.displayed
        this.isArgeeManagementId= data.data.isArgeeManagementId
        this.carouselList[0].items = []
        this.carouselList2[0].items = []
        data.data.listRecommand = []
        data.data.listAll.forEach((item, index) => {
          if (data.data.listRecommandSize > 0) {
            data.data.listRecommand.push(item);
            this.carouselList[0].items.push(data.data.listRecommand[index])
            this.carouselList[0].items[index].cost = this.filter(this.carouselList[0].items[index].managementFinancingAmount * this.carouselList[0].items[index].managementRate * 0.01)
          } else {
            if (item && (index < 3)) {
              this.carouselList[0].items.push(data.data.listAll[index])
              this.carouselList[0].items[index].cost = this.filter(this.carouselList[0].items[index].managementFinancingAmount * this.carouselList[0].items[index].managementRate * 0.01)
            }
          }

        });
        this.carouselListNumberA = data.data.listRecommandSize
        this.fapiaomoney = data.state
      }
    },
    applyRateChange() {
      if (this.financingForm.exceptRate && this.returnBUttonSpecial == 3) {
        this.backgroundColor = true
        console.log('ok')
        var str = (100 - (this.financingForm.exceptRate)).toString()
        var reg = RegExp(/./);
        if (str.match(reg)) {
          var percent = (str || '').split('.')
          var num = percent.join('')
          var percentStr = '0' + '.' + Number(num)
        }
        this.financingForm.exceptMoney = this.fapiaomoney
        this.financingForm.exceptMoney = this.filterOut(this.financingForm.exceptMoney) * percentStr
        if (isNaN(this.financingForm.exceptMoney)) {
          this.backgroundColor = true
          this.backgroundColorTime = false
          this.financingForm.exceptMoney = '--'
        } else if (!isNaN(this.financingForm.exceptMoney)) {
          this.financingForm.exceptMoney = this.fapiaomoney
          this.financingForm.exceptMoney = this.filterOut(this.financingForm.exceptMoney) * percentStr
          this.financingForm.exceptMoney = this.filter(this.financingForm.exceptMoney)
        }
        this.financingForm.exceptMoney = this.filter(this.financingForm.exceptMoney)
      }
      else if (!this.financingForm.exceptRate&& this.thirdAccountShow) {
        this.backgroundColor = false
        this.financingForm.exceptMoney = this.filter(this.fapiaomoney)
      } else if (this.financingForm.exceptRate) {
        this.backgroundColor = true
        this.backgroundColorTime = true
      }
      this.indexIndex++
    },
    // 选择资方的返回上一页
    returnButton() {
      this.firstBox = true
      this.secondBox = false
      this.thirdBox = false
      window.scrollTo(0, 0)
      this.getDate()
    },
    // 我的邀约的返回上一页
    async returnButton1() {
      if (this.returnBUttonSpecial == 3) {
        this.thirdBox = false
        this.secondBox = false
        this.firstBox = true
        window.scrollTo(0, 0)
        this.getDate()
      }
      else {
        this.financingForm.supplierRate = this.financingForm.supplierRate.split('%')[0]
        this.financingForm.managementRate = this.financingForm.managementRate.split('%')[0]
        this.firstBox = false
        this.carouselIndex = 0
        this.secondBox = true
        this.thirdBox = false
        window.scrollTo(0, 0)
        let data = await findAllMyManageByFinancingNum('financingNum=' + this.finName)
        this.financingForm = data.data.listAll[0]
        this.carouselListNumber = data.data.listAllSize
        this.carouselList[0].items = []
        this.carouselList2[0].items = []
        data.data.listRecommand = []
        data.data.listAll.forEach((item, index) => {
          if (data.data.listRecommandSize > 0) {
            data.data.listRecommand.push(item);
            this.carouselList[0].items.push(data.data.listRecommand[index])
            this.carouselList[0].items[index].cost = this.filter(this.carouselList[0].items[index].managementFinancingAmount * this.carouselList[0].items[index].managementRate * 0.01)
          } else {
            if (item && (index < 3)) {
              this.carouselList[0].items.push(data.data.listAll[index])
              this.carouselList[0].items[index].cost = this.filter(this.carouselList[0].items[index].managementFinancingAmount * this.carouselList[0].items[index].managementRate * 0.01)
            }
          }

        });
        this.carouselListNumberA = data.data.listRecommandSize
        this.fapiaomoney = data.state
      }
      this.backgroundColor =false
      this.backgroundColorTime=true
    },
    // 接受邀约
    acceptInvitation(id, managementId,financingNum,isAgree) {
      console.log('dd',id)
      this.financingForm.financingNum = financingNum
      this.financingForm.managementId = managementId
      this.mangentId = managementId
      this.isAgree = isAgree
      this.dataId = id
      this.firstBox = false
      this.secondBox = false
      this.thirdBox = true
      this.repaymentDateShow = true
      this.companyNameThirdShow = false
      this.thirdAccountShow = false
      this.accountShow = '融资金额:'
      this.rateShow = '融资利率:'
      this.exceptAccountShow = '期望融资金额:'
      this.exceptRateShow = '期望融资利率:'
      window.scrollTo(0, 0)
      this.$nextTick(() => {
        this.carouselList[0].items.forEach((item, index) => {
          if (id == item.id) {
            console.log(item)
            this.financingForm.id=item.id
            this.financingForm.supplyRepaymentDay = item.supplyRepaymentDay
            this.financingForm.managerRepaymentDay = item.managerRepaymentDay
            this.financingForm.timeRatio = item.timeRatio
            this.financingForm.supplierRepaymentDate = item.supplierRepaymentDate
            this.financingForm.managementRepaymentDate = item.managementRepaymentDate
            this.financingForm.paymentTimeRatio = item.paymentTimeRatio
            this.financingForm.amountRatio = String(item.amountRatio)
            this.financingForm.supplierRate = item.supplierRate
            this.financingForm.managementRate = item.managementRate
            this.financingForm.rateRatio = item.rateRatio
            this.financingForm.supplierFinancingAmount = this.filter(item.supplierFinancingAmount)
            this.financingForm.managementFinancingAmount = this.filter(item.managementFinancingAmount)
            this.financingForm.supplierRate = this.financingForm.supplierRate + '%'
            this.financingForm.managementRate = this.financingForm.managementRate + '%'
            // this.financingForm.exceptMoney = this.filter(this.fapiaomoney)
          }
        })
      })
      this.exceptArrivalChange()

    },
    // 轮播图
    carouselChange(val) {
      this.carouselIndex = val
    },
    async nextPrimaryAsync() {
      let params = {}
      console.log("dataId",this.dataId)
      params.id = this.dataId ? this.dataId : this.financingForm.id
      params.financingNum = this.financingForm.financingNum
      if (this.financingForm.exceptArrival) {
        params.supplierArrivalDate = this.financingForm.exceptArrival
      } else {
        params.supplierArrivalDate = this.financingForm.managerRepaymentDay
      }
      if (this.financingForm.exceptRepayment) {
        params.supplierRepaymentDate = this.financingForm.exceptRepayment
      } else {
        params.supplierRepaymentDate = this.financingForm.managementRepaymentDate
      }
      if (this.financingForm.exceptMoney) {
        params.supplierFinancingAmount = this.filterOut(this.financingForm.exceptMoney)
      } else {
        this.financingForm.managementFinancingAmount = this.filterOut(this.financingForm.managementFinancingAmount)
        params.supplierFinancingAmount = this.financingForm.managementFinancingAmount
        this.financingForm.managementFinancingAmount = this.filter(this.financingForm.managementFinancingAmount)
      }
      if (this.financingForm.exceptRate) {
        params.supplierRate = this.financingForm.exceptRate
      } else {
        params.supplierRate = this.financingForm.managementRate.split('%')[0]
        params.supplierFinancingAmount = this.filterOut(this.financingForm.managementFinancingAmount)
      }
      params.managementArrivalDate = this.financingForm.managerRepaymentDay
      params.managementFinancingAmount = this.filterOut(this.financingForm.managementFinancingAmount)
      params.managementRate = this.financingForm.managementRate.split('%')[0]
      params.managementRepaymentDate = this.financingForm.managementRepaymentDate
      params.managerId = this.financingForm.managementId
      console.log(params)
      let data = await updateFinancingComparisonInfo(params)
      this.backgroundColor =false
      this.backgroundColorTime=true
      console.log(data)
    },
    // 提交
    async nextPrimary(index) {
      if (((this.financingForm.exceptArrival && !this.financingForm.exceptRepayment) || (!this.financingForm.exceptArrival && this.financingForm.exceptRepayment)) && (this.returnBUttonSpecial == 2)) {
        this.$message({
          message: "期望到款天数和期望还款时间需同时填写!",
          type: 'warning'
        })
      } else if (this.financingForm.exceptMoney && (parseInt(this.filterOut(this.financingForm.exceptMoney)) > parseInt(this.fapiaomoney))) {
        console.log(parseInt(this.filterOut(this.financingForm.exceptMoney)))
        console.log(parseInt(this.fapiaomoney))
        this.$message({
          message: '您的融资金额不能高于发票总金额!',
          type: 'warning'
        });
      } else if ((this.financingForm.exceptArrival < 8) && (this.financingForm.exceptArrival)) {
        this.$message({
          message: '期望到款天数需要大于7天!',
          type: 'warning'
        });
      } else {
        if (index == 1 && this.backgroundColor == true && this.backgroundColorTime == true) {
          this.$refs.financingForm1.validate(async (valid) => {
            if (valid) {
             
              this.nextPrimaryAsync()
              this.firstBox = true
              this.secondBox = false
              this.thirdBox = false
            } else {
              return false;
            }
          });
          this.getDate()
        } else if (index == 2 && this.backgroundColor == false && this.backgroundColorTime == true) {
          let data = await acceptInvite('financingNum=' + this.financingForm.financingNum + '&manageId=' + this.financingForm.managementId)
          this.firstBox = true
          this.secondBox = false
          this.thirdBox = false
          this.getDate()
        } else if (index == 3) {
          let data = await refuseInvite('financingNum=' + this.financingForm.financingNum + '&manageId=' + this.financingForm.managementId)
          this.firstBox = true
          this.secondBox = false
          this.thirdBox = false
          let _this = this
          let timeId = setTimeout(function () {
            _this.getDate()
            clearTimeout(timeId)
          }, 1000)
        }
      }
    },
    // 合同处理
    async contractProcessing(row) {
      this.contractProcessingDialog = true
      this.contractProcessSubmitdata.financingNum = row.financingNum
      this.contractProcessSubmitdata.manageId = row.manageId
      this.contractProcessSubmitdata.supplierId = row.userId
      this.contractProcessSubmitdata.codeFlag = row.codeFlag
      let data = await downloadContractSupplier('financing_num=' + this.contractProcessSubmitdata.financingNum + '&manager_id=' + this.contractProcessSubmitdata.manageId + '&supplier_id=' + this.contractProcessSubmitdata.supplierId)
      this.upLoadFileNumber = data.data
      data = await getContractSupplier('financing_num=' + this.contractProcessSubmitdata.financingNum + '&manager_id=' + this.contractProcessSubmitdata.manageId +
        '&supplier_id=' + this.contractProcessSubmitdata.supplierId)
      this.upLoadFileName = data.data
      this.file = this.upLoadFileName.supplier
      this.upLoadFileName.manager.forEach((item, index) => {
        if (!this.file[index]) {
          this.file[index] = {
            url: '',
            name: '',
            be: '',
            type: ''
          }
        }
      })
    },
    handleRemove(file, fileList) {
      this.uploadform.file = fileList.length
    },
    async financingDetails(row) {
      this.financingDetailsDialog = true
      let data = await getFinancingCompList('financingNum=' + row.financingNum + '&manageId=' + row.manageId)
      this.financingForm = data.data.list[0]
      this.financingForm.supplierFinancingAmount = this.filter(this.financingForm.supplierFinancingAmount)
      this.financingForm.managementFinancingAmount = this.filter(this.financingForm.managementFinancingAmount)
      this.financingForm.supplierRate = this.financingForm.supplierRate + '%'
      this.financingForm.managementRate = this.financingForm.managementRate + '%'
    },
    // 合同处理提交
    async contractProcessSubmit() {
      let data = await uploadContractSupplier(this.contractProcessSubmitdata.financingNum, this.contractProcessSubmitdata.manageId, this.contractProcessSubmitdata.supplierId, this.contractProcessSubmitdata.codeFlag, this.file)
      this.contractProcessingDialog = false
      this.getDate()
    }
  }
}
</script>

<style scoped>
::v-deep.detail_width1000 .el-dialog {
  width: 1000px;
  height: 620px;
}

::v-deep.detail_width1000 .el-form-item__content {
  display: flex;
}

::v-deep.detail_width1000 .el-form-item {
  margin-left: 20px;
  margin-right: 20px;
}

::v-deep.detail_width1000 .el-dialog--center .el-dialog__footer {
  margin-top: 15px;
  text-align: right;
}

.bg-purple-light {
  border-radius: 10px;
  margin-left: -20px;
  width: 460px;
  height: 460px;
  background: #f7fafc;
}

.bg-purple-light2 {
  border-radius: 10px;
  margin-left: 0px;
  width: 450px;
  height: 460px;
  background: #f7fafc;
}

.form_item1 {
  /* margin-left: 40px; */
  margin-right: 20px;
  padding-top: 20px;
  /* padding-left: 20px; */
}

.form_item1-1 {
  /* margin-left: 40px; */
  margin-right: 20px;
  padding-top: 20px;
  /* padding-left: 20px; */
}

.form_item2 {
  margin-left: -8px;
  margin-right: 10px;
}

.form_item3 {
  margin-left: -8px;
  margin-right: 10px;
}

.form_item4 {
  margin-left: -8px;
  margin-right: 10px;
}

::v-deep.form_item1 .el-input__inner {
  display: inline-block;
  width: 325px;
  margin-left: 25px;
}

::v-deep.form_item3 .el-input__inner {
  display: inline-block;
  width: 320px;
  margin-left: 0px;
}

::v-deep.form_item1-1 .el-input__inner {
  display: inline-block;
  width: 325px;
  margin-left: 20px;
}

::v-deep.form_item1-2 .el-input__inner {
  display: inline-block;
  width: 325px;
  margin-left: 20px;
}

::v-deep.form_item1-3 .el-input__inner {
  display: inline-block;
  width: 325px;
  margin-left: 8px;
}

::v-deep.form_item1-3 .el-input__inner {
  display: inline-block;
  width: 325px;
  margin-left: 8px;
}

::v-deep.form_item4 .el-input__inner {
  margin-left: 25px;
  display: inline-block;
  width: 325px;
}

::v-deep.form_item5 .el-form-item__content {
  display: inline-block;
  margin-left: 20px;
}

/* ::v-deep.form_item2 .el-input__inner{
                                                                                                                                                                                                                                                                                                                                                                                                                                                                      display: inline-block;
                                                                                                                                                                                                                                                                                                                                                                                                                                                                      width: 285px;
                                                                                                                                                                                                                                                                                                                                                                                                                                                                      margin-left: 25px;   
                                                                                                                                                                                                                                                                                                                                                                                                                                                                  } */
/* .management_box .contract_download_box{
                                                                                                                                                                                                                                                                                                                                                                                                                                                                      width: 120px !important;
                                                                                                                                                                                                                                                                                                                                                                                                                                                                  } */
.supplier_box .flex_center_box {
  width: 410px;
}

.heightRepaymentDate1 {
  height: 380px;
}

.heightRepaymentDate2 {
  height: 330px;
}

.add_invoice_message li {
  float: left;
  width: 60px;
  height: 60px;
  margin-right: 12px;
  background: #f7fafc;
  border-radius: 4px;
  border: 1px solid #e1e7ea;
}

.add_invoice_message ul {
  display: block !important;
  margin-top: 10px;
}

::v-deep.supplier_box .el-form-item__content {
  margin-top: 60px;
  margin-bottom: -20px;
}

::v-deep.add_invoice_messages .el-input__prefix {
  left: 92%;
}

::v-deep .el-form-item__content ul {
  display: none;
}

::v-deep .el-form-item__content .ul {
  display: block;
}

/* ::v-deep .el-button--primary:focus, .el-button--primary:hover {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                      background: #dae4e9 !important;
                                                                                                                                                                                                                                                                                                                                                                                                                                                                      border: 1px solid #dae4e9 !important;
                                                                                                                                                                                                                                                                                                                                                                                                                                                                  } */
.dialog_width500>>>.el-dialog--center .el-dialog__body {
  padding-left: 20px;
  padding-right: 20px;
}

::v-deep .exceptArrivalClass .el-date-editor--date:hover .el-input__prefix {
  display: none;
}

::v-deep .exceptRepaymentClass .el-date-editor--date:hover .el-input__prefix {
  display: none;
}

.search_flex_box>>>.el-date-editor .el-range-separator {
  display: none;
}

.leftArrow /deep/ .el-carousel__arrow--left {
  cursor: not-allowed;
}

.leftArrow /deep/ .el-carousel__arrow--left i {
  cursor: not-allowed;
}

.rightArrow /deep/ .el-carousel__arrow--left {
  background: #339c9b;
}

.rightArrow /deep/ .el-carousel__arrow--right {
  background: #dae4e9;
  cursor: not-allowed;
}

.rightArrow /deep/ .el-carousel__arrow--right i {
  cursor: not-allowed;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

::v-deep .el-badge {
  color: #fb6476;
}

::v-deep .el-badge__content.is-fixed {
  top: 6px;
}

::v-deep .el-badge__content.is-fixed.is-dot {
  right: -1px;
}

::v-deep .el-badge__content {
  background-color: #fb6476;
}

.table_oper .item_color_money_complete {
  color: #e1e7ea;
  cursor: not-allowed;
}

.apply_box_top_left {
  width: 340px;
  height: 176px;
  margin-right: 60px;
  padding: 20px;
  background: #339c9b;
  box-shadow: 0px 10px 20px 0px rgba(47, 160, 159, 0.23);
  border-radius: 14px;
}

.apply_box_total {
  display: flex;
  align-items: center;
  width: 300px;
  height: 136px;
  padding-top: 20px;
  padding-bottom: 24px;
  padding-left: 16px;
  background: rgba(237, 242, 245, 0.2);
  box-shadow: 0px 4px 8px 0px rgba(0, 102, 101, 0.23);
  border-radius: 14px;
}

.echarts_box {
  height: 250px;
  margin-top: -40px;
}

.search_flex_box>>>.el-form-item {
  margin-bottom: 20px;
}

.search_flex_box>>>.el-form-item__content {
  float: left;
  width: 300px;
}

.search_flex_box>>>.el-input__inner {
  height: 30px;
}

.search_flex_box>>>.el-form-item:last-child {
  margin-bottom: 0;
}

.search_flex_box>>>.el-form-item:nth-child(3) {
  margin-right: 0;
}

.search_flex_box>>>.el-date-editor--daterange.el-input__inner {
  width: 300px;
}

.search_flex_box>>>.el-date-editor .el-range-separator {
  display: none;
}

.search_flex_box>>>.el-select .el-input.is-focus .el-input.is-active .el-input__inner {
  border: 1px solid #ffffff;
}

.search_condition {
  flex: 1;
}

.select_class>>>.el-input__icon {
  line-height: 30px;
}

.second_choose_financing {
  width: auto;
  height: 612px;
}

.second_choose_financing ul {
  margin-left: 40px;
}

.second_choose_financing li {
  float: left;
  position: relative;
  width: 420px;
  height: 612px;
  margin-right: 80px;
  padding-top: 50px;
  padding-bottom: 44px;
  padding-left: 25px;
  padding-right: 25px;
  background: #ffffff;
  box-shadow: 0px 10px 20px 0px rgba(12, 79, 71, 0.15);
  border-radius: 10px;
}

.second_choose_financing li:last-child {
  margin-right: 0;
}

.second_title_box {
  margin-bottom: 50px;
}

.li_title {
  margin-bottom: 50px;
  font-size: 16px;
  color: #162747;
  text-align: center;
}

.li_content_bg {
  width: 254px;
  height: 254px;
  margin: 0 auto;
  padding: 31px;
  background: linear-gradient(26deg, #81f3ec 0%, #fffb69 100%);
  border-radius: 50%;
}

.li_content_text {
  width: 192px;
  height: 192px;
  padding-top: 40px;
  border-radius: 50%;
  background: linear-gradient(168deg, #ffffff 0%, #ffffff 54%, #e2e2e2 100%);
  box-shadow: 14px 30px 30px 0px #339c9b;
}

.li_content_text span {
  display: block;
  font-size: 14px;
  color: #92a2bc;
  text-align: center;
}

.li_content_text span:first-child {
  margin-bottom: 20px;
}

.li_content_text span:nth-child(2) {
  margin-bottom: 30px;
  font-size: 38px;
  font-weight: bold;
  color: #162747;
}

.li_bottom {
  margin-top: 50px;
}

.limit_date {
  display: flex;
  width: 370px;
  height: 64px;
  margin: 0 auto;
  padding-top: 12px;
  padding-bottom: 10px;
  background: #ffffff;
  box-shadow: 0px 2px 10px 0px rgba(12, 79, 71, 0.16);
}

.limit_date_box1 {
  flex: 1;
}

.limit_date_box2 {
  flex: 1;
}

.limit_span span {
  display: block;
  text-align: center;
}

.limit_span span:first-child {
  margin-bottom: 8px;
  font-size: 12px;
  color: #92a2bc;
}

.limit_span span:last-child {
  font-size: 14px;
  color: #162747;
}

.split_line {
  width: 1px;
  height: 30px;
  margin-top: 6px;
  border: 1px solid #e1e7ea;
}

.li_bottom_button {
  margin-top: 40px;
}

.li_bottom_button_auto div {
  background: #339c9b;
}

.second_invitation {
  position: relative;
  width: 100%;
  height: auto;
  padding-top: 30px;
  padding-bottom: 120px;
  padding-left: 30px;
  padding-right: 30px;
  background: #ffffff;
  box-shadow: 0px 10px 20px 0px rgba(12, 79, 71, 0.15);
  border-radius: 10px;
}

.invitation_box {
  /* height: 380px; */
  padding-top: 30px;
  padding-left: 30px;
  padding-right: 30px;
  background: #f7fafc;
  border-radius: 10px;
}

.second_invitation_left {
  flex: 1;
  margin-right: 20px;
}

.second_invitation_title {
  margin-bottom: 20px;
  font-size: 18px;
  color: #162747;
}

.my_second_invitation_title {
  margin-bottom: 60px !important;
}

.message_compare {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
  padding-left: 130px;
  padding-right: 185px;
  font-size: 14px;
  color: #162747;
}

.compare_color1 {
  color: #339c9b;
}

.compare_color2 {
  color: #fb6476;
}

.second_invitation_right {
  flex: 1;
}

.my_feedback>>>.el-input__prefix {
  position: absolute;
  left: 508px;
}

.button_change {
  position: absolute;
  right: 210px;
  bottom: 50px;
}

.button_change1 {
  position: absolute;
  right: 390px;
  bottom: 50px;
}

.button_change2 {
  position: absolute;
  right: 30px;
  bottom: 50px;
}

.items {
  color: #606266;
}

.add_class>>>.el-form-item__label {
  min-width: 142px;
  margin-bottom: 20px;
  height: 24px;
}

.contract_download_box {
  width: 60px;
  height: 60px;
  margin-bottom: 20px;
  margin-right: 14px;
  padding-left: 15px;
  padding-top: 15px;
  background: #f7fafc;
  border-radius: 4px;
  border: 1px solid #e1e7ea;
}

.contract_download_box img {
  width: 30px;
  height: 30px;
}

.icon-yasuobao {
  font-size: 30px;
  position: relative;
  left: 14px;
  top: 15px;
}

.download_button {
  width: 160px;
  height: 40px;
  margin-top: -20px;
  color: #339c9b;
  background: #ffffff;
  border-radius: 32px;
  border: 1px solid #e1e7ea;
}

.download_button.el-button--default:hover,
.download_button.el-button--default:focus {
  color: #339c9b;
}

.download_button span {
  font-size: 16px;
}

.download_button i {
  margin-right: 6px;
  font-size: 18px;
}

.upload-demo {
  /* float: right; */
  width: 160px;
  height: 40px;
  margin-bottom: 10px;
  margin-right: 30px;
  margin-left: 110px;
  background: #ffffff;
  border-radius: 32px;
  border: 1px solid #e1e7ea;
}

.icon-icon-shangchuan-14x14 {
  margin-right: 6px;
  font-size: 18px;
  color: #339c9b;
}

.upload-demo span {
  font-size: 16px;
  color: #339c9b;
  line-height: 40px;
  text-align: center;
}

.add_class>>>.el-upload {
  position: relative;
  left: 20px;
}

.add_class>>>.el-form-item__content {
  font-size: 20px;
  color: #7c969b;
}

.add_class>>>.el-upload__tip {
  float: left;
  left: 66px;
  margin-top: 15px;
}

::v-deep .el-upload-list--picture .el-upload-list__item {
  float: none;
}

.button_class1 {
  background: #dae4e9;
  border: 1px solid #dae4e9;
  cursor: not-allowed;
}

.button_class2 {
  background: #339c9b;
  border: 1px solid #339c9b;
}

.bg_button1 {
  background: #339c9b;
}

.bg_button2 {
  background: #dae4e9;
  border: 1px solid #dae4e9;
  cursor: not-allowed;
}

.bg_button2:hover {
  background: #dae4e9;
  border: 1px solid #dae4e9;
  cursor: not-allowed;
}

::v-deep .el-button--primary.is-disabled {
  background-color: #dae4e9;
  border-color: #dae4e9;
}

.management_box {
  width: 100%;
  height: auto;
  margin-bottom: 10px;
  padding-top: 20px;
  padding-left: 30px;
  padding-right: 30px;
  background: #f7fafc;
  border-radius: 10px;
}

.management_box ul {
  /* height: 264px; */
  overflow: auto;
}

.management_box li {
  margin-bottom: 10px;
}

.management_box li:last-child {
  margin-bottom: 0;
}

.management_box_title {
  height: 60px;
}

.management_box_title div {
  height: 40px;
}

.file_name {
  width: 80%;
  margin-bottom: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.supplier_box {
  position: relative;
  width: 100%;
  /* height: 354px; */
  padding-bottom: 8px;
  padding-top: 20px;
  padding-left: 20px;
  background: #f7fafc;
  border-radius: 10px;
}

.supplier_box .contract_download_box_upload {
  padding-left: 0;
  padding-top: 11.5px;
}

.supplier_box>>>.el-form-item__content {
  display: inline-block;
}

.message_tips {
  height: 24px;
  line-height: 24px;
  font-size: 14px;
}

.icon-WORD {
  font-size: 30px;
  color: #2a5699;
}

.icon-Microsoft-Excel {
  font-size: 30px;
  color: #107b0f;
}

.download_button_special {
  float: right;
  margin-top: -42px;
  margin-right: 30px;
}

.supplier_box>>>.el-upload-list {
  position: absolute;
  left: -110px;
  width: auto !important;
}

::v-deep .el-upload-list--picture .el-upload-list__item {
  width: auto;
}

.red_warning_box {
  width: 420px;
  height: 40px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: -145px;
  background: #ffffff;
  box-shadow: 0px 2px 10px 0px rgba(12, 79, 71, 0.16);
  border-radius: 4px;
}

.red_warning_box i {
  margin-right: 2px;
  font-size: 16px;
  color: #fb6476;
}

.red_warning_box span {
  font-size: 14px;
  color: #fb6476;
}

::v-deep .el-upload-list {
  margin-top: 58px;
}

.supplier_box ul {
  margin-left: -133px;
}

.supplier_box ul span {
  font-size: 14px;
  color: #162747;
}

.close_icon {
  position: absolute;
  top: -6px;
  left: 52px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #fb6476;
}

.close_icon i {
  position: relative;
  top: -10px;
  left: 2px;
  font-size: 8px;
  color: #ffffff;
}

.supplier_box li {
  position: relative;
}
.agree_tips{
  position: absolute;
  top: 80px;
  left:50%;
  transform: translateX(-50%);
  font-size:12px;
  color: #fb6476;
}

@media screen and (max-width: 1664px) {
  ::v-deep.detail_width1000 .el-dialog {
    width: 800px;
    height: 440px;
  }

  ::v-deep.detail_width1000 .el-form-item__content {
    display: flex;
  }

  ::v-deep.detail_width1000 .el-form-item {
    margin-left: 20px;
    margin-right: 20px;
  }

  ::v-deep.detail_width1000 .el-dialog--center .el-dialog__footer {
    margin-top: 2px;
    text-align: right;
  }

  .bg-purple-light {
    border-radius: 10px;
    margin-left: -20px;
    width: 370px;
    height: 320px;
    background: #f7fafc;
  }

  .bg-purple-light2 {
    border-radius: 10px;
    margin-left: -10px;
    width: 370px;
    height: 320px;
    background: #f7fafc;
  }

  .form_item1 {
    /* margin-left: 40px; */
    margin-right: 20px;
    padding-top: 20px;
    /* padding-left: 20px; */
  }

  .form_item1-1 {
    /* margin-left: 40px; */
    margin-right: 20px;
    padding-top: 20px;
    /* padding-left: 20px; */
  }

  .form_item2 {
    margin-left: -8px;
    margin-right: 10px;
  }

  .form_item3 {
    margin-left: -8px;
    margin-right: 10px;
  }

  .form_item4 {
    margin-left: -8px;
    margin-right: 10px;
  }

  ::v-deep.form_item1 .el-input__inner {
    display: inline-block;
    width: 250px;
    margin-left: 25px;
  }

  ::v-deep.form_item2 .el-input__inner {
    display: inline-block;
    width: 250px;
  }

  ::v-deep.form_item3 .el-input__inner {
    display: inline-block;
    width: 250px;
  }

  ::v-deep.form_item1-1 .el-input__inner {
    display: inline-block;
    width: 250px;
    margin-left: 20px;
  }

  ::v-deep.form_item1-2 .el-input__inner {
    display: inline-block;
    width: 250px;
    margin-left: 20px;
  }

  ::v-deep.form_item1-3 .el-input__inner {
    display: inline-block;
    width: 250px;
    margin-left: 8px;
  }

  ::v-deep.form_item1-3 .el-input__inner {
    display: inline-block;
    width: 250px;
    margin-left: 8px;
  }

  ::v-deep.form_item4 .el-input__inner {
    margin-left: 25px;
    display: inline-block;
    width: 250px;
  }

  ::v-deep.form_item5 .el-form-item__content {
    display: inline-block;
    margin-left: 20px;
  }

  .supplier_box .flex_center_box {
    width: 266px;
  }

  .add_invoice_message li {
    float: left;
    width: 40px;
    height: 40px;
    margin-right: 8px;
    background: #f7fafc;
    border-radius: 4px;
    border: 1px solid #e1e7ea;
  }

  .add_invoice_message ul {
    margin-top: 6px;
  }

  .dialog_width500>>>.el-dialog--center .el-dialog__body {
    padding-left: 13px;
    padding-right: 13px;
  }

  ::v-deep .el-badge__content.is-fixed {
    top: 4px;
  }

  ::v-deep .el-badge__content.is-fixed.is-dot {
    right: -1px;
  }

  .apply_box_top_left {
    width: 226px;
    height: 117px;
    margin-right: 40px;
    padding: 13px;
    background: #339c9b;
    box-shadow: 0px 6px 13px 0px rgba(47, 160, 159, 0.23);
    border-radius: 10px;
  }

  .apply_box_total {
    display: flex;
    align-items: center;
    width: 200px;
    height: 90px;
    padding-top: 13px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 13px;
    background: rgba(237, 242, 245, 0.2);
    box-shadow: 0px 4px 8px 0px rgba(0, 102, 101, 0.23);
    border-radius: 10px;
  }

  .echarts_box {
    height: 166px;
    margin-top: -26px;
  }

  .search_flex_box>>>.el-form-item {
    margin-bottom: 13px;
  }

  .search_flex_box>>>.el-form-item__content {
    float: left;
    width: 200px;
  }

  .search_flex_box>>>.el-input__inner {
    height: 20px;
  }

  .search_flex_box>>>.el-date-editor--daterange.el-input__inner {
    width: 200px;
  }

  .select_class>>>.el-input__icon {
    line-height: 20px;
  }

  .second_choose_financing {
    width: auto;
    height: 408px;
  }

  .second_choose_financing ul {
    margin-left: 26px;
  }

  .second_choose_financing li {
    float: left;
    width: 280px;
    height: 427px;
    margin-right: 53px;
    padding-top: 33px;
    padding-bottom: 29px;
    padding-left: 16px;
    padding-right: 16px;
    background: #ffffff;
    box-shadow: 0px 6px 13px 0px rgba(12, 79, 71, 0.15);
    border-radius: 6px;
  }

  .second_title_box {
    margin-bottom: 33px;
    margin-left: -110px;
    transform: scale(0.8);
  }

  .li_title {
    margin-bottom: 33px;
    font-size: 12px;
    color: #162747;
    text-align: center;
  }

  .li_content_bg {
    width: 169px;
    height: 169px;
    margin: 0 auto;
    padding: 21px;
    background: linear-gradient(26deg, #81f3ec 0%, #fffb69 100%);
    border-radius: 50%;
  }

  .li_content_text {
    width: 128px;
    height: 128px;
    padding-top: 26px;
    border-radius: 50%;
    background: linear-gradient(168deg, #ffffff 0%, #ffffff 54%, #e2e2e2 100%);
    box-shadow: 9px 20px 20px 0px #339c9b;
  }

  .li_content_text span {
    display: block;
    font-size: 12px;
    color: #92a2bc;
    text-align: center;
  }

  .li_content_text span:first-child {
    margin-bottom: 13px;
  }

  .li_content_text span:nth-child(2) {
    margin-bottom: 20px;
    font-size: 25px;
    font-weight: bold;
    color: #162747;
  }

  .li_bottom {
    margin-top: 33px;
  }

  .limit_date {
    display: flex;
    width: 370px;
    height: 64px;
    margin-left: -60px;
    padding-top: 12px;
    padding-bottom: 10px;
    background: #ffffff;
    box-shadow: 0px 2px 10px 0px rgba(12, 79, 71, 0.16);
    transform: scale(0.7);
  }

  .limit_span span:first-child {
    margin-bottom: 5px;
    font-size: 12px;
    color: #92a2bc;
  }

  /* .limit_span span:last-child{
                                                                                                                                                                                                                                                                                                                                                                                                                                                                          font-size: 12px;
                                                                                                                                                                                                                                                                                                                                                                                                                                                                          color: #162747;
                                                                                                                                                                                                                                                                                                                                                                                                                                                                      } */
  .split_line {
    width: 1px;
    height: 30px;
    margin-top: 4px;
    border: 1px solid #e1e7ea;
  }

  .li_bottom_button {
    margin-top: 26px;
  }

  .second_invitation {
    position: relative;
    width: 100%;
    height: auto;
    padding-top: 20px;
    padding-bottom: 60px;
    padding-left: 20px;
    padding-right: 20px;
    background: #ffffff;
    box-shadow: 0px 6px 13px 0px rgba(12, 79, 71, 0.15);
    border-radius: 6px;
  }

  .invitation_box {
    /* height: 280px; */
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    background: #f7fafc;
    border-radius: 6px;
  }

  .second_invitation_left {
    flex: 1;
    margin-right: 13px;
  }

  .second_invitation_title {
    margin-bottom: 13px;
    font-size: 12px;
    color: #162747;
  }

  .my_second_invitation_title {
    margin-bottom: 40px !important;
  }

  .message_compare {
    width: 100%;
    height: auto;
    margin-bottom: 13px;
    padding-left: 86px;
    padding-right: 118px;
    font-size: 12px;
    color: #162747;
  }

  .message_compare span:last-child {
    position: relative;
    left: 15px;
  }

  .message_special {
    padding-left: 70px !important;
    padding-right: 100px !important;
  }

  .my_feedback>>>.el-input__prefix {
    position: absolute;
    left: 322px;
  }

  .button_change {
    position: absolute;
    right: 140px;
    bottom: 14px;
  }

  .button_change1 {
    position: absolute;
    right: 260px;
    bottom: 14px;
  }

  .button_change2 {
    position: absolute;
    right: 20px;
    bottom: 14px;
  }

  .add_class>>>.el-form-item__label {
    min-width: 95px;
    margin-bottom: 13px;
    height: 16px;
    transform: scale(0.95);
  }

  .supplier_box>>>.el-form-item__label {
    min-width: 95px;
  }

  .supplier_box .contract_download_box_upload {
    padding-left: 5px;
    padding-top: 9px;
  }

  .contract_download_box {
    width: 40px;
    height: 40px;
    margin-bottom: 13px;
    margin-right: 9px;
    padding-left: 10px;
    padding-top: 10px;
    background: #f7fafc;
    border-radius: 4px;
    border: 1px solid #e1e7ea;
  }

  .contract_download_box img {
    width: 20px;
    height: 20px;
  }

  .icon-yasuobao {
    font-size: 20px;
    position: relative;
    left: 9px;
    top: 10px;
  }

  .download_button {
    width: 106px;
    height: 26px;
    margin-top: -13px;
    color: #339c9b;
    background: #ffffff;
    border-radius: 22px;
    border: 1px solid #e1e7ea;
  }

  .download_button span {
    font-size: 12px;
  }

  .download_button i {
    margin-right: 4px;
    font-size: 12px;
  }

  .upload-demo {
    /* float: right; */
    width: 106px;
    height: 26px;
    margin-bottom: 6px;
    margin-right: 20px;
    margin-left: 67px;
    background: #ffffff;
    border-radius: 22px;
    border: 1px solid #e1e7ea;
  }

  .icon-icon-shangchuan-14x14 {
    margin-right: 4px;
    font-size: 12px;
    color: #339c9b;
  }

  .upload-demo span {
    font-size: 12px;
    color: #339c9b;
    line-height: 26px;
    text-align: center;
  }

  .add_class>>>.el-upload {
    position: relative;
    left: 8px;
  }

  .add_class>>>.el-form-item__content {
    font-size: 14px;
    color: #7c969b;
  }

  .add_class>>>.el-upload__tip {
    float: left;
    left: 44px;
    margin-top: 10px;
  }

  .management_box {
    width: 100%;
    height: auto;
    margin-bottom: 6px;
    padding-top: 13px;
    padding-left: 20px;
    padding-right: 20px;
    background: #f7fafc;
    border-radius: 6px;
  }

  .management_box ul {
    /* height: 176px; */
    overflow: auto;
  }

  .management_box li {
    margin-bottom: 6px;
  }

  .management_box_title {
    height: 40px;
  }

  .management_box_title div {
    font-size: 12px;
    height: 26px;
    transform: scale(0.95);
  }

  .file_name {
    width: 80%;
    margin-bottom: 6px;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .supplier_box {
    position: relative;
    width: 100%;
    /* height: 236px; */
    padding-bottom: 8px;
    padding-top: 13px;
    padding-left: 13px;
    background: #f7fafc;
    border-radius: 6px;
  }

  /* .message_tips{
                                                                                                                                                                                                                                                                                                                                                                                                                                                                          height: 16px;
                                                                                                                                                                                                                                                                                                                                                                                                                                                                          line-height: 16px;
                                                                                                                                                                                                                                                                                                                                                                                                                                                                          font-size: 12px;
                                                                                                                                                                                                                                                                                                                                                                                                                                                                          transform: scale(0.85);
                                                                                                                                                                                                                                                                                                                                                                                                                                                                      } */
  .icon-WORD {
    font-size: 20px;
    color: #2a5699;
  }

  .icon-Microsoft-Excel {
    font-size: 20px;
    color: #107b0f;
  }

  .download_button_special {
    float: right;
    margin-top: -28px;
    margin-right: 20px;
  }

  .supplier_box>>>.el-upload-list {
    position: absolute;
    left: -73px;
    width: auto !important;
  }

  .red_warning_box {
    width: 280px;
    height: 26px;
    margin-top: 6px;
    margin-bottom: 6px;
    margin-left: -97px;
    background: #ffffff;
    box-shadow: 0px 2px 10px 0px rgba(12, 79, 71, 0.16);
    border-radius: 4px;
  }

  .red_warning_box i {
    position: relative;
    left: 20px;
    top: 1px;
    /* margin-right: 2px; */
    font-size: 12px;
    color: #fb6476;
  }

  .red_warning_box span {
    font-size: 12px;
    color: #fb6476;
    transform: scale(0.85);
  }

  ::v-deep .el-upload-list {
    margin-top: 38px;
  }

  .supplier_box ul {
    margin-left: -89px;
  }

  .supplier_box ul span {
    font-size: 12px;
    color: #162747;
  }

  .close_icon {
    position: absolute;
    top: -6px;
    left: 32px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #fb6476;
    transform: scale(0.8);
  }

  .close_icon i {
    position: relative;
    top: -3px;
    left: 2px;
    font-size: 8px;
    color: #ffffff;
  }

  .heightRepaymentDate1 {
    height: 280px;
  }

  .heightRepaymentDate2 {
    height: 230px;
  }
}</style>